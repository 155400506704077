export const START_LOADING_INVOICES = "START_LOADING_INVOICES";
export const STOP_LOADING_INVOICES = "STOP_LOADING_INVOICES";
export const SET_INVOICES = "SET_INVOICES";

export interface Invoice {
    id: number;
    name: string;
    request_opportunity_id: number;
    request_id: number;
    client_id: number;
    trainer_id: number;
    franchise_id: number;
    status: string;
    is_proposal_sent: boolean;
    proposal_sent_date: Date;
    proposal_link: string;
    is_proposal_signed: boolean;
    proposal_signed_date: Date
    ghl_opportunity_id: string;
    ghl_contact_id: string;
    created_at: Date;
    updated_at: Date;
    User?: any;
    Trainer?: any;
    InvoiceItems?: any;
    RequestOpportunity?: any;
    RequestTrainer?: any;
    Franchise?: any;
    discount?: any
}


interface StartLoadingInvoice {
    type: typeof START_LOADING_INVOICES;
  }
  
  interface StopLoadingInvoice {
    type: typeof STOP_LOADING_INVOICES;
  }
  
  interface SetInvoices {
    type: typeof SET_INVOICES;
    payload: {
      data: Invoice[];
      page: number;
      lastPage: number;
      total: number;
    };
  }
  
  
  export type InvoicesActionType = 
    | StartLoadingInvoice
    | StopLoadingInvoice
    | SetInvoices;
