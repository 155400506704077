import { ThunkAction } from "redux-thunk";
import { fetchInvoicesByTrainerIdApi, postCreateInvoiceApi, postSendInvoiceApi } from "../../../api";
import { AppState } from "../../reducers";
import { Action } from "redux";
import { startLoading, stopLoading } from "../common/loading";
import { SET_INVOICES, START_LOADING_INVOICES, STOP_LOADING_INVOICES } from "../../../models/app/invoices";


export const postCreateInvoiceAction = (data: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any) => {
        try {
            const response = await postCreateInvoiceApi(data).xhr;
            dispatch(fetchInvoicesByTrainerIdAction(data.trainer_id, {search: "", status: "All", page: 1, perPage: 10}));
            return response;
        } catch (err) {
            throw err;
        }
    };
};

export const fetchInvoicesByTrainerIdAction = (trainerId: number, filter: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING_INVOICES)(dispatch, getState, null)
            const response = await fetchInvoicesByTrainerIdApi(trainerId, filter).xhr;
            if(response){
                dispatch({
                    type: SET_INVOICES,
                    payload: response
                })
            }
            return response;
        } catch (err) {
            throw err;
        }finally{
            stopLoading(STOP_LOADING_INVOICES)(dispatch, getState, null)
        }
    };
};

export const postSendInvoiceAction = (data: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING_INVOICES)(dispatch, getState, null)
            const response = await postSendInvoiceApi(data).xhr;
            dispatch(fetchInvoicesByTrainerIdAction(data?.invoice?.trainer_id, {search: "", status: "All"}));
            return response;
        } catch (err) {
            throw err;
        }finally{
            stopLoading(STOP_LOADING_INVOICES)(dispatch, getState, null)
        }
    };
};