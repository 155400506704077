import axios from 'axios';

var qs = require('qs');

export function postCreateInvoiceApi(data:any ,config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "post",
      url: `/invoice`,
      data
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}


export function fetchInvoicesByTrainerIdApi(trainerId:number ,filter: any, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "get",
      url: `/invoice/fetch-by-trainer-id/${trainerId}`,
      params: {
        ...filter
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function postSendInvoiceApi(data:any ,config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "post",
      url: `/invoice/send-through-ghl`,
      data
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}