import { combineReducers } from "redux";
import { 
  FranchiseKeyActionType, 
  FranchiseKeyState, 
  IntegrationActionType, 
  IntegrationState, 
  SET_FRANCHISE_KEY, 
  SET_INTEGRATION_API_KEY, 
  START_LOADING_INTEGRATION, 
  STOP_LOADING_INTEGRATION,
  SET_PIPELINES,
  SET_CUSTOM_FIELDS
} from "../../../models";

const initialState: IntegrationState & FranchiseKeyState = {
  apiKey: "",
  tags: [],
  defaultTag: "",
  purchaseTag: "",
  defaultProviderTag: "",
  id: null,
  user_id: null,
  franchise_id: null,
  publishable_key: "",
  secret_key: "",
}

const initialStatePipeline: any = {}
const initialStateCustomFields: any = {}

export default combineReducers({
  data: (state: IntegrationState = initialState, action: IntegrationActionType | FranchiseKeyActionType) => {
    switch(action.type) {
      case SET_INTEGRATION_API_KEY:
        state = { ...state, ...action.payload };
        return state;
      case SET_FRANCHISE_KEY:
        state = { ...state, ...action.payload };
        return state;
      default:
        return state;
    }
  },
  isLoading: (state = false, action: IntegrationActionType) => {
    switch (action.type) {
      case START_LOADING_INTEGRATION:
          return true;

      case STOP_LOADING_INTEGRATION:
          return false;

      default:
          return state;
    }
  },
  pipelines: (state: any = initialStatePipeline, action: IntegrationActionType) => {
    switch (action.type) {
      case SET_PIPELINES:
        state = { ...state, ...action.payload }
      default:
        return state
    }
  },
  handymanCustomFields: (state: any = initialStateCustomFields, action: IntegrationActionType) => {
    switch (action.type) {
      case SET_CUSTOM_FIELDS:
        state = { ...state, ...action.payload }
      default:
        return state
    }
  }
})