import { SET_FAILELD, SET_FRANCHISE_KEY, SET_INTEGRATION_API_KEY, SET_SUCCESS, START_LOADING_INTEGRATION, STOP_LOADING_INTEGRATION, SET_PIPELINES, SET_CUSTOM_FIELDS } from "../../../models";
import { startLoading, stopLoading } from "../common/loading";
import { addIntegrationApi, getApiKeyAndTags, getFranchiseKeysApi, updateIntegrationSettingsApi, getPipelinesForLocationApi, getCustomFieldsForLocationApi } from "../../../api";

export const addIntegrationKey = ({apiKey = "", ghlLocationKey = '', franchiseId = "", defaultTag = "", purchaseTag = "", defaultProviderTag = "", handymanStages={},  success} : {apiKey: string; ghlLocationKey: string; franchiseId: string; defaultTag: string; purchaseTag: string; defaultProviderTag: string; handymanStages: any; success: (data: any) => void;}): any => {
  return async (dispatch: any, getState: any) => {

    try {
      startLoading(START_LOADING_INTEGRATION)(dispatch, getState, null);
      const response = await addIntegrationApi({apiKey, ghlLocationKey, franchiseId, defaultTag, purchaseTag, defaultProviderTag, handymanStages}).xhr;
      if(response) {
        dispatch({type: SET_SUCCESS, payload: {success: true, message: 'api key added'}});
        dispatch({
          type: SET_INTEGRATION_API_KEY,
          payload: {
            apiKey,
            defaultTag: (defaultTag === "N/A") ? "" : defaultTag,
            purchaseTag:  (purchaseTag === "N/A") ? "" : purchaseTag,
            defaultProviderTag: (defaultProviderTag === "N/A") ? "" : defaultProviderTag,
            handymanStages: handymanStages
          }});
      }
      success(response);
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING_INTEGRATION)(dispatch, getState, null)
    }
  }
}

export const getIntegrationKeyAndTags = ({franchiseId = ""} : {franchiseId: string}): any => {
  return async (dispatch: any, getState: any) => {

    try {
      startLoading(START_LOADING_INTEGRATION)(dispatch, getState, null);
      const response : {data: any} = await getApiKeyAndTags({franchiseId}).xhr;
      if(response.data) {
        dispatch({type: SET_INTEGRATION_API_KEY, payload: response.data});
      }
    } finally {
      stopLoading(STOP_LOADING_INTEGRATION)(dispatch, getState, null)
    }
  }
}

export const getPipelinesForLocation = (franchise_id: any): any => {
  return async (dispatch: any, getState: any) => {

    try {
      startLoading(START_LOADING_INTEGRATION)(dispatch, getState, null);
      const response : {data: any} = await getPipelinesForLocationApi(franchise_id).xhr;
      if(response.data) {
        dispatch({type: SET_PIPELINES, payload: response.data});
      }
    } finally {
      stopLoading(STOP_LOADING_INTEGRATION)(dispatch, getState, null)
    }
  }
}

export const getCustomFieldsForLocation = (franchise_id: any): any => {
  return async (dispatch: any, getState: any) => {

    try {
      startLoading(START_LOADING_INTEGRATION)(dispatch, getState, null);
      const response : {data: any} = await getCustomFieldsForLocationApi(franchise_id).xhr;
      if(response.data) {
        dispatch({type: SET_CUSTOM_FIELDS, payload: response.data});
      }
    } finally {
      stopLoading(STOP_LOADING_INTEGRATION)(dispatch, getState, null)
    }
  }
}

export const updateIntegrationSettings = ({franchiseId = "", defaultTag = "", purchaseTag = "", defaultProviderTag = "", handymanStages={}, handymanPipeline={}, assignedProField= {}, proposalLinkField={}, success} : {franchiseId: string; defaultTag: string; purchaseTag: string; defaultProviderTag: string; handymanStages: any; handymanPipeline: any; assignedProField: any; proposalLinkField: any; success: (data: any) => void;}): any => {
  return async (dispatch: any, getState: any) => {

    try {
      startLoading(START_LOADING_INTEGRATION)(dispatch, getState, null);
      const response = await updateIntegrationSettingsApi({franchiseId, defaultTag, purchaseTag, defaultProviderTag, handymanStages, handymanPipeline, assignedProField, proposalLinkField}).xhr;
      if(response) {
        dispatch({type: SET_SUCCESS, payload: {success: true, message: 'tags updated'}});
        dispatch({
          type: SET_INTEGRATION_API_KEY,
          payload: {
            defaultTag: (defaultTag === "N/A") ? "" : defaultTag,
            purchaseTag:  (purchaseTag === "N/A") ? "" : purchaseTag,
            defaultProviderTag: (defaultProviderTag === "N/A") ? "" : defaultProviderTag,
            handymanStages: handymanStages
          }});
          success(response)
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: {message: error} });
    } finally {
      stopLoading(STOP_LOADING_INTEGRATION)(dispatch, getState, null)
    }
  }
}

export const getFranchiseKeysAction = (): any => {
  return async (dispatch: any, getState: any)=>{
    try {
      startLoading(START_LOADING_INTEGRATION)(dispatch, getState, null);
      let response = await getFranchiseKeysApi().xhr;
      console.log(response);
      if(response){
        dispatch({ type: SET_FRANCHISE_KEY, payload: response });
        return response;
      }
    } catch (error) {
      dispatch({type: SET_FAILELD, payload: { message: error }});
    } finally {
      stopLoading(STOP_LOADING_INTEGRATION)(dispatch, getState, null);
    }
  }
}