import axios from 'axios';

export function addIntegrationApi(data: {apiKey: string; ghlLocationKey: string; franchiseId: string, defaultTag: string; purchaseTag: string; defaultProviderTag: string; handymanStages: any} ,config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "post",
      url: `/integration`,
      data
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getApiKeyAndTags(data: {franchiseId: string} ,config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
      method: "get",
      url: `/integration/${data.franchiseId}`,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getPipelinesForLocationApi(franchise_id:any ,config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
      method: "get",
      url: `/integration/get/pipelines/${franchise_id}`,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getCustomFieldsForLocationApi(franchise_id:any ,config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
      method: "get",
      url: `/integration/get/customfields/${franchise_id}`,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function updateIntegrationSettingsApi(data: {franchiseId: string, defaultTag: string; purchaseTag: string; defaultProviderTag: string; handymanStages: any; handymanPipeline: any; assignedProField: string; proposalLinkField: any;} ,config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, string>({
      method: "put",
      url: `/integration`,
      data
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}


export function getFranchiseKeysApi() {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "get",
    url: `/get-franchise-keys`
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}