export const START_LOADING_INTEGRATION = "START_LOADING_INTEGRATION";
export const STOP_LOADING_INTEGRATION = "STOP_LOADING_INTEGRATION";
export const SET_INTEGRATION_API_KEY = "SET_INTEGRATION_API_KEY";
export const SET_PIPELINES = "SET_PIPELINES";
export const SET_CUSTOM_FIELDS = "SET_CUSTOM_FIELDS";
export const SET_FRANCHISE_KEY = "SET_FRANCHISE_KEY";
// export const SET_TAGS = "SET_TAGS";
// export const SET_DEFAULT_TAG = "SET_DEFAULT_TAG";

interface Tags {
  name: string;
  id: string;
}

export interface IntegrationState {
  apiKey?: string;
  tags: Tags[];
  defaultTag?: string;
  purchaseTag?: string;
  defaultProviderTag?: string;
}

export interface FranchiseKeyState {
  id: number;
  user_id: number;
  franchise_id: number;
  publishable_key: string;
  secret_key: string;
}
interface StartLoadingIntegration {
  type: typeof START_LOADING_INTEGRATION;
}

interface StopLoadingIntegration {
  type: typeof STOP_LOADING_INTEGRATION;
}

interface SetIntegrationKey {
  type: typeof SET_INTEGRATION_API_KEY;
  payload: IntegrationState;
}
interface SetPipelines {
  type: typeof SET_PIPELINES;
  payload: any;
}
interface SetCustomFields {
  type: typeof SET_CUSTOM_FIELDS;
  payload: any;
}

interface SetFranchiseKey {
  type: typeof SET_FRANCHISE_KEY;
  payload: FranchiseKeyState;
}

export type IntegrationActionType = 
  | StartLoadingIntegration
  | StopLoadingIntegration
  | SetIntegrationKey
  | SetPipelines
  | SetCustomFields;

export type FranchiseKeyActionType =
  | SetFranchiseKey;