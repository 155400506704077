import { combineReducers } from "redux";
import { FranchiseKeyActionType } from "../../../models";
import {
  Invoice,
  InvoicesActionType,
  SET_INVOICES,
  START_LOADING_INVOICES,
  STOP_LOADING_INVOICES,
} from "../../../models/app/invoices";

const initialStateInvoice: Invoice = {
  id: 0,
  name: "",
  request_opportunity_id: 0,
  request_id: 0,
  client_id: 0,
  trainer_id: 0,
  franchise_id: 0,
  status: "",
  is_proposal_sent: false,
  proposal_sent_date: null,
  proposal_link: "",
  is_proposal_signed: false,
  proposal_signed_date: null,
  ghl_opportunity_id: "",
  ghl_contact_id: "",
  created_at: null,
  updated_at: null,
  User: null,
  Franchise: null,
  InvoiceItems: [],
  RequestOpportunity: null,
  RequestTrainer: null,
  Trainer: null,
  discount: null,
};
// Define the initial state for the list reducer
const initialStateList = {
  data: [] as Array<Invoice>,
  lastPage: 1,
  page: 1,
  perPage: 10,
  total: 0,
};

export default combineReducers({
  list: (
    state: typeof initialStateList = initialStateList,
    action: InvoicesActionType
  ) => {
    switch (action.type) {
      case SET_INVOICES:
        if (!action.payload || !Array.isArray(action.payload.data)) {
          return state; // Prevent reducer from breaking
        }

        return {
          ...state,
          data:
            action.payload.page === 1
              ? action.payload.data // Replace on refresh
              : [...state.data, ...action.payload.data], // Append on pagination
          page: action.payload.page,
          lastPage: action.payload.lastPage,
          total: action.payload.total,
        };

      default:
        return state;
    }
  },
  isLoading: (state = false, action: InvoicesActionType) => {
    switch (action.type) {
      case START_LOADING_INVOICES:
        return true;

      case STOP_LOADING_INVOICES:
        return false;

      default:
        return state;
    }
  },
});
